<template>
	<div class="row">
		<div class="col-lg-12 mb-3" v-if="isMobile">
			<table class="mx-auto">
				<tr>
					<td>
						<img v-lazy="imageCheck" alt="Tasa" class="img-fluid pb-3" width="100" height="100">
					</td>
					<td>
						<p class="h3 text-white">Tasa ideal</p>
					</td>
					
				</tr>
				<tr>
					<td>
						<img v-lazy="imageCheck" alt="Cuotas" class="img-fluid pb-3" width="100" height="100">
					</td>
					<td>
						<p class="h3 text-white">Cuotas Cómodas</p>
					</td>
					
				</tr>
				<tr>
					<td>
						<img v-lazy="imageCheck" alt="Proceso" class="img-fluid pb-3" width="100" height="100">
					</td>
					<td>
						<p class="h3 text-white">Proceso online</p>
					</td>
				</tr>
			</table>
		</div>

		<div class="col-lg-12" v-else>
			<div class="row">
				<div class="col-lg-4 mt-3 mb-3 text-center">
					<div class="d-flex align-items-center justify-content-center flex-row flex-lg-column">
						<div class="mr-2">
							<img v-lazy="imageCheck" alt="Tasa" class="img-fluid" width="100" height="100">
						</div>
						<div>
							<p class="h3 text-white">Tasa ideal</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 mt-3 mb-3 text-center">
					<div class="d-flex align-items-center justify-content-center flex-row flex-lg-column">
						<div class="mr-2">
							<img v-lazy="imageCheck" alt="Cuotas" class="img-fluid" width="100" height="100">
						</div>
						<div>
							<p class="h3 text-white">Cuotas Cómodas</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 mt-3 mb-3 text-center">
					<div class="d-flex align-items-center justify-content-center flex-row flex-lg-column">
						<div class="mr-2">
							<img v-lazy="imageCheck" alt="Proceso" class="img-fluid" width="100" height="100">
						</div>
						<div>
							<p class="h3 text-white">Proceso online</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default{
		data(){
			return {
				imageCheck: require('@/assets/image/checkbox.webp')
			}
		},
		computed:{
			isMobile() {
				return this.$store.getters.isMobile
			},
		}
	}
</script>